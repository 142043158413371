<template>
    <div class="space-y-10">
        <div>
            <form class="space-y-6" @submit.prevent="handleSaveRole">
                <Input label="Name" type="text" id="name" v-model="role.name" :error="errors.name" />
                <Input label="Description" type="text" id="description" v-model="role.description" :error="errors.description" />

                <SubmitButton label="Save" submittingLabel="Saving..." />
            </form>
        </div>

        <div class="space-y-6">
            <h3 class="text-lg leading-6 font-medium text-gray-900">Danger zone</h3>

            <div class="flex justify-between align-items border border-grey-300 rounded-md p-6">
                <div>
                    <h4 class="font-medium">Delete role</h4>
                    <p class="text-gray-400">Once confirmed, this operation can't be undone. Assigned users will lose their role</p>
                </div>
                <button type="button" @click="isDeletingRole = true" class="px-4 py-3 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500">Delete role</button>
            </div>
        </div>
    </div>
</template>

<script>
    // import RoleService from '../../services/RoleService';

    export default {
        data() {
            return {
                isDeletingRole: false,
                role: {},
                errors: {},
            };
        },
        computed: {
            selected_role() {
                return this.$store.getters['roles/role'];
            },
        },
        watch: {
            selected_role: function (val) {
                this.role = val;
            },
        },
        methods: {
            handleSaveRole() {},
        },
    };
</script>
